<template>
  <div class="admin-users" v-bind="initData">
    <div class="admin-users-list" v-if="users">
      <ul class="admin-users-list-table" width="100%">
        <li class="row" v-for="(user, uidx) in users" :key="uidx">
          <div class="col info">
            <div class="col name">{{ user.name }}</div>
            <div class="col email">{{ user.email }}</div>
            <div class="col roles">{{ locale.words.roles[user.roles[0]] }}</div>
          </div>
          <div class="col options">
            <div class="options-actions">
              <a class="pointer tooltip relative" @click="changePassLink(user)">
                <i class="fa fa-key"></i>
                <span class="tooltiptext tool-auto" noevents>Modificar contraseña</span>
              </a>
              <router-link class="option tooltip relative" :to="{ path: 'edit', query: { id: user._id } }">
                <i class="fa fa-pencil"></i>
                <span class="tooltiptext tool-auto" noevents>Editar</span>
              </router-link>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="load-more-block relative" :class="{ loading: loading }" v-if="morepage">
      <div class="load-more-block-content" v-if="!loading">
        <button class="click-on-bottom" @click="getUsers((cpage += 1))">Ver mas</button>
      </div>
      <div class="onScrollLoading preventStayOnBottom" v-else-if="loading"></div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import api from "../../api";
import store from "../../store/index.js";
import Swal from "sweetalert2";
export default {
  data: () => {
    return {
      loading: false,
      users: null,
      locale: null,
      cpage: 1,
      morepage: false,
      total: null,
    };
  },
  methods: {
    getUsers: async function (page) {
      this.loading = true;
      page = page >= 1 ? page : 1;
      const _host = `${process.env.VUE_APP_API_HOST}admin/users/?page=${page}`;
      const response = await api.get(`${_host}`);
      if (page > 1) {
        this.users.push(...response.data.users);
      } else {
        this.users = response.data.users;
      }
      this.morepage = response.data.hasMore;
      this.total = response.data.total;
      this.cpage = this.morepage ? this.cpage : 1;
      this.loading = false;
    },
    getDataLang: async function () {
      this.locale = await store.getters["localeLang"];
    },
    changePassLink: async function (user) {
      const _this = this;
      Swal.fire({
        title: "Esta seguro ?",
        html: "Se enviará un link al email del usuario para cambiar su clave",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#2196f3",
        cancelButtonColor: "#bd0909",
        confirmButtonText: "Si, enviar",
        cancelButtonText: "No, cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          _this.dataSending();
          const link = this.$global.getApp("autologin").link;
          const {
            data: { message, error },
          } = await api.get(this.$global.getApp("administration").api + `/emailsend/loginrestore?link=${link}&user=${user.user}`);
          if (error) {
            Swal.fire({
              title: "Error",
              html: message,
              icon: "error",
              confirmButtonText: "OK",
              timerProgressBar: true,
              confirmButtonColor: "#bd0909",
            });
            return;
          }
          Swal.fire({
            title: "Correo enviado",
            text: "Se realizó el envio del correo con las indicaciones para restablecer la contraseña",
            icon: "success",
            confirmButtonText: "Aceptar",
            timerProgressBar: false,
            confirmButtonColor: "#bd0909",
          });
        }
      });
    },
    dataSending: function () {
      Swal.fire({
        title: "Enviando",
        text: "Solicitando el link",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    },
    dataError: function (message) {
      Swal.fire({
        title: "Error",
        html: message,
        icon: "error",
        confirmButtonText: "Revisar",
        timerProgressBar: true,
        confirmButtonColor: "#bd0909",
      });
    },
  },
  computed: {
    initData: async function () {
      await this.getDataLang();
      await this.getUsers();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars.scss";
.admin-users {
  &-list {
    &-table {
      border-collapse: collapse;
      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $mpadding/2 $mpadding;
        border-bottom: solid 1px $alto;
        &:hover {
          box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 #3c40434d, 0 1px 3px 1px #3c404326;
          z-index: 2;
          background: #fff;
        }
        .col {
          word-break: break-word;
          .tooltiptext {
            word-break: normal;
          }
          &.info {
            width: 100%;
            .col {
              width: 100%;
              &.name {
                font-weight: bold;
              }
            }
          }
        }
        .options {
          text-align: right;
          padding: 0;
          &-actions {
            display: flex;
            float: right;
            > a {
              padding: $mpadding/2 $mpadding;
            }
          }
        }
      }
    }
  }
  @media (min-width: $tablet_width) {
    &-list {
      &-table {
        .row {
          .col {
            &.info {
              display: flex;
              .col {
                &.name {
                  font-weight: bold;
                }
                &:nth-child(1) {
                  width: 33%;
                }
                &:nth-child(2) {
                  width: 50%;
                }
              }
            }
          }
          .roles {
            text-align: right;
            width: 20%;
          }
        }
      }
    }
  }
}
</style>
